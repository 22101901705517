@import 'scss/variables';

body {
  .PreviewModal {
    background-color: $color-black;
    border-radius: 12px !important;

    // mu-main-modal
    :global(.mu-close-btn) {
      color: $color-white;

      &:hover {
        color: $color-white;
      }
    }

    .IframeWrapper {
      min-width: 650px;
      height: 413px;
      overflow: hidden;
      cursor: pointer;
    }

    .Footer {
      display: flex;
      justify-content: flex-end;
      margin-top: 6px;
      // justify-content: center;
      // align-items: flex-end;
      // height: 100%;
    }
  }

  .ModalPreview {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    padding: 25px 10px;
    width: 100%;
    height: 100%;
    transition: opacity $trans;
    opacity: 1;
    pointer-events: all;
    z-index: 9999999990;

    .Overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: rgba($color-black, 0.95);
      z-index: 9999999990;
    }

    .SliderModal {
      max-width: 100%;
      max-height: 90vh;
      width: 100%;
      z-index: 9999999991;

      .IframeWrapper {
        position: relative;
        padding-top: 56.25%;
        width: 100%;
        border-radius: 4px;
        overflow: hidden;
        cursor: pointer;

        .Iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: transparent;
          border: none;
        }
      }

      @media (min-width: $sm) {
        margin-top: 40px;
      }
    }

    .XMark {
      position: absolute;
      top: 15px;
      right: 15px;
      padding: 10px;
      width: 45px;
      height: 45px;
      color: $color-white;
      background-color: rgba($color-black2, 0.3);
      border: none;
      border-radius: 50%;
      transition: background-color $trans;
      z-index: 9999999992;

      &:hover {
        background-color: rgba($color-black2, 0.6);
      }
    }

    .Footer {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      bottom: 15%;
      margin-top: 0;
      color: $color-white;
      background-color: transparent;
      border: none;
      z-index: 9999999992;
      top: auto;

      @media (min-width: $sm) {
        bottom: 7%;
      }
    }

    @media (min-width: $sm) {
      align-items: flex-start;
    }
  }
}
