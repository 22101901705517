@import 'scss/variables';

body {
  .PlatformMeta {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;

    .Dot,
    .Industry,
    .MinInvestment {
      font-size: 16px;
      line-height: 24px;
      color: $color-black4;
    }
  }
}
