@import 'scss/variables';

body {
  .PlatformsBg {
    background-color: rgba($color-black9, 0.5);

    .PlatformsTab {
      display: grid;
      grid-template-columns: 1fr;
      column-gap: 70px;
      padding-top: 20px;
      padding-bottom: 40px;

      .RightSide {
        .Count {
          min-width: 100px;
        }

        .FilterMob {
          display: flex;
          flex-direction: column-reverse;
          justify-content: space-between;
          align-items: flex-start;

          .FilterType {
            display: flex;
            align-items: flex-start;
            gap: 16px;
            margin-bottom: 24px;

            .ContaimerType {
              display: flex;
              width: 100%;
              flex-wrap: wrap;
              gap: 8px;
            }
          }

          @media (min-width: $sm) {
            align-items: center;
            flex-direction: row;
          }
        }

        .Investments {
          > * {
            margin-bottom: 16px;
          }

          .ShowMoreBtn {
            margin: 30px auto;
            margin-bottom: 0;

            :global(.mu-content) {
              display: flex;
              align-items: center;
              gap: 8px;
            }
          }
        }
      }

      .LeftSide {
        display: none;
        position: sticky;
        top: 80px;
        padding-top: 0px;
        height: calc(100vh - 145px);
        overflow-y: auto;

        @media (min-width: $md) {
          display: block;
        }
      }

      @media (min-width: $md) {
        grid-template-columns: 345px 1fr;
        padding-top: 40px;
        padding-bottom: 40px;
      }
    }
  }
}
