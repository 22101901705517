@import 'scss/variables';

body {
  .DiscoverHeader {
    height: 136px;
    background-color: transparent;
    background-image: none;

    .Link {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 100%;
      padding-top: 20px;
      margin-bottom: 16px;

      .Title {
        color: $color-black;
        margin-bottom: 10px;

        > span {
          color: $color-red5;

          @media (min-width: $sm) {
            color: $color-white;
          }
        }

        @media (min-width: $sm) {
          margin-bottom: 15px;
          color: $color-white;
        }
      }

      .Description {
        color: $color-black4;

        @media (min-width: $sm) {
          color: $color-black7;
        }
      }

      @media (min-width: $sm) {
        padding-top: 0px;
        margin-bottom: 0px;
      }
    }

    @media (min-width: $sm) {
      height: 250px;
      background-repeat: no-repeat;
      background-position: center center;
      background-color: $color-black;
      background-image: url('https://assets.moneymade.io/images/AssetPage/Asset%203.png');
      background-size: contain;
      background-size: auto 260px;
    }
  }
}
