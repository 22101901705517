@import 'scss/variables';

body {
  .LockBlock {
    position: absolute;
    width: calc(100% - 40px);
    z-index: 1;

    .Header {
      display: flex;
      margin-bottom: 139px;
      flex-direction: row;
      align-items: center;

      .Title {
        margin-right: 8px;
      }

      .LockIcon {
        color: $color-red5;
      }

      @media (min-width: $sm) {
        margin-bottom: 68px;
      }
    }

    .Center {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .Top {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 24px;
        margin-left: auto;
        margin-right: auto;
        max-width: 538px;

        .TopTextStart {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          justify-content: center;

          .TextStart {
            margin-right: 4px;

            .Member {
              color: $color-red5;
            }
          }
        }

        .TextEnd {
          margin-left: 0;

          @media (min-width: $sm) {
            margin-left: 10px;
          }
        }

        @media (min-width: $sm) {
          flex-direction: row;
        }
      }
    }

    @media (min-width: $sm) {
      width: calc(100% - 60px);
    }
  }
}
