@import 'scss/variables';

body {
  .CardGraph {
    width: 100%;
    max-width: 196px;
    height: 100%;

    .Header {
      display: flex;
      flex-direction: row;
      gap: 5px;

      .Name {
        color: $color-black4;
      }
    }

    .Graph {
      display: block;
      height: 50px;
    }
  }
}
