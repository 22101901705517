@import 'scss/variables';

body {
  .Search {
    width: 100%;

    :global(.mu-container) {
      :global(.mu-icon-after) {
        color: $color-black3;

        .CloseBtn {
          background: transparent;
          border: none;
        }
      }
    }
  }
}
