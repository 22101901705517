@import 'scss/variables';

body {
  .DiscoverSieve {
    margin-bottom: 25px;

    .Title {
      margin-bottom: 22px;
      color: $color-black2;

      @media (max-width: $md) {
        font-size: 32px;
        line-height: 38px;
      }

      @media (min-width: $md) {
        padding-bottom: 24px;
        border-bottom: 1px solid $color-black8;
      }
    }

    .Sieve {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: row-reverse;
      gap: 10px;
      overflow: auto;

      .FilterOptions {
        display: none;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
        gap: 10px;
        opacity: 1;

        @media (min-width: $md) {
          display: flex;
        }
      }

      .MatchMe {
        padding-bottom: 20px;
        border-bottom: 1px solid #e8e8eb;

        > p {
          white-space: nowrap;
        }

        .Link {
          margin-top: 8px;
          > p {
            color: #ed2348;
          }

          :hover {
            text-decoration: underline;
          }
        }
      }

      .FilterType {
        width: 100%;

        .Items {
          .Item {
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 2px;
            padding: 12px;
            border-radius: 8px;
            cursor: pointer;
            color: $color-teal;

            :global(.mu-p2) {
              > i {
                margin-right: 8px;

                &.Icon {
                  color: $color-teal;
                }
              }
            }

            &:last-child {
              margin-bottom: 0;
            }

            &:hover {
              background: rgba(35, 205, 195, 0.05);
            }

            &.Active {
              background: rgba(35, 205, 195, 0.05);

              &::before {
                width: 100%;
                opacity: 1;
              }
            }

            :global(.mu-container) {
              z-index: 2;
            }
          }
        }

        .ShowMore {
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          color: #9e9ea5;
          background-color: transparent;
          border: none;
          margin-top: 10px;

          &:hover {
            color: $color-black;
          }

          > i {
            margin-left: 10px;
          }
        }
      }

      .FilterBtn {
        @media (min-width: $md) {
          display: none;
        }
      }

      .Search {
        flex: 1;
        min-width: 150px;

        @media (min-width: $md) {
          width: 100%;
        }
      }

      @media (min-width: $md) {
        justify-content: space-between;
        overflow-x: auto;
        flex-direction: column;
      }
    }

    .Count {
      margin-top: 25px;
    }
  }
}
