@import 'scss/variables';

body {
  .SubForm {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: $card-shadow;
    gap: 10px;

    .HeaderImg {
      object-fit: cover;
      width: 250px;
      height: 190px;
      margin-bottom: 20px;
    }

    .HeaderTitle {
      position: relative;
      margin-bottom: 10px;
      width: 310px;
      height: 20px;
      background: rgba(247, 255, 142, 0.7);
      border-radius: 20px;

      > span {
        position: absolute;
        top: 0px;
        left: 10px;
        white-space: nowrap;
      }
    }

    .SecondaryTitle {
      margin-top: 30px;
      margin-left: 10px;
      margin-bottom: 20px;
    }

    .InputForm {
      width: 290px;
      margin-right: 20px;
      margin-bottom: 10px;
    }

    .Button {
      width: 310px;
      max-width: 290px;
      margin-right: 20px;
      margin-bottom: 25px;
    }

    @media (min-width: $md) {
      flex-direction: row;
    }
  }
}
