@import 'scss/variables';

body {
  .Offers {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
    background-color: $color-white;
    border-radius: 12px;
    border: 1px solid $color-black;
    box-shadow: 16px 16px 0px #f2f2f4;
    margin-bottom: 16px !important;
    padding: 24px;

    .LeftSide {
      .Header {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 12px;
        margin-bottom: 16px;

        img {
          max-width: 48px;
          max-height: 48px;
          object-fit: cover;
          border-radius: 12px;
        }

        .Title {
          display: flex;
          flex-direction: column;
          gap: 4px;

          .NewOffer {
            display: flex;
            flex-direction: row;
            align-items: center;
            white-space: nowrap;
            width: 90px;
            border-radius: 5px;
            background-color: rgba(35, 205, 195, 0.1);
            padding: 3px 12px 5px 12px;

            > p {
              color: $color-teal;
            }
          }
        }
      }
    }

    .RightSide {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .Header {
        display: flex;
        flex-direction: row;
        gap: 24px;

        .DataPoint {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 4px;

          .Title {
            color: $color-black4;
          }

          @media (min-width: $sm) {
            align-items: flex-end;
          }
        }
      }
      .Btn {
        > button {
          width: 100%;
        }
      }

      @media (min-width: $sm) {
        gap: 27px;
      }
    }

    @media (min-width: $sm) {
      flex-direction: row;
      padding: 24px 20px;
      // margin: 52px 32px !important;
    }
  }
}
