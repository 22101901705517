@import 'scss/variables';

body {
  .HeaderBannerContainer {
    .HeaderBanner {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      max-width: 1200px;
      padding: 0px 24px;
      border-radius: 12px;
      height: 120px;

      .Left {
        display: flex;
        align-items: center;
        gap: 16px;

        img {
          object-fit: cover;
          object-position: center;
          border-radius: 12px;
        }

        .Title {
          margin-bottom: 5px;
        }
      }

      .Right {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;

        .Button {
          display: flex;
          align-items: center;
          white-space: nowrap;
          gap: 8px;
          padding: 8px 16px;
          border-radius: 20px;
        }

        .ImageSmall {
          max-width: 120px;
          height: 120px;
        }
      }
    }
  }
}
