@import 'scss/variables';

body {
  .SkeletonFilters {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 10px;

    :global(.mu-skeleton) {
      border-radius: 18px;
    }

    .OnlyDesktop {
      display: none;

      @media (min-width: $md) {
        display: block;
      }
    }

    .OnlyMobile {
      display: block;

      @media (min-width: $md) {
        display: none;
      }
    }

    @media (min-width: $md) {
      flex-direction: column;
    }
  }
}
