@import 'scss/variables';
@import 'scss/mixi';

body {
  .LockComponent {
    .Lock {
      @include Lock();
    }
  }
}
