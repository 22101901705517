@import 'scss/variables';

body {
  .FilterBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 15px;
    font-size: 15px;
    line-height: 20px;
    white-space: nowrap;
    width: 100%;
    color: $color-black2;
    background-color: $color-white;
    border: 1px solid $color-black7;
    border-radius: 18px;
    transition: border-color $trans;

    &.FilterBtnActive {
      color: $color-red5;
      border-color: $color-red5;

      .DropDown,
      .Close {
        color: inherit;
      }
    }

    &:hover {
      border-color: $color-black5;
    }

    .DropDown,
    .Close {
      margin-left: 10px;
      color: $color-black3;
    }
  }

  .FilterList {
    min-width: 300px;
    box-shadow: $card-shadow;

    .FilterListItem {
      :global(.mu-checked) {
        background-color: $color-black;
        border-color: $color-black;
      }

      :global(.mu-container) {
        padding: 0;
      }
    }

    .Footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 15px;
      padding: 10px;
      border-top: 1px solid $color-black9;

      &:hover {
        background-color: transparent;
      }

      .ClearBtn {
        padding: 8px 10px;
        font-size: 15px;
        font-weight: 500;
        line-height: 20px;
        color: #9e9ea5;
        border: 1px solid #9e9ea5;
        border-radius: 100px;
        background-color: transparent;
      }
    }
  }
}
