@import 'scss/variables';

.CustomTooltip {
  position: absolute;
  padding: 4px 8px;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  line-height: 16px;
  // white-space: nowrap;
  color: $color-black;
  background-color: $color-white;
  border-radius: 4px;
  box-shadow: $popover-shadow;
  z-index: 99999999100;

  &.Blue {
    color: $color-white;
    background-color: #61dbfb;
  }

  &.Red {
    color: $color-white;
    background-color: #e4605e;
  }

  &.RightPos {
    transform: translate(5px, -50%);
  }

  * {
    color: inherit;
  }
}
