@import 'scss/variables';

body {
  .MobBanner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    width: 100%;
    border-radius: 12px;
    padding: 20px 16px;

    &.Header {
      border-radius: 0px;
    }

    .Left {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 10px;

      .ImageSmall {
        width: 53px;
      }

      .Contant {
        display: flex;
        flex-direction: column;

        .Text {
          white-space: break-spaces;
        }
      }
    }

    .Right {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;

      .Button {
        display: flex;
        align-items: center;
      }
    }
  }
}
