@import 'scss/variables';

body {
  .InvestmentCard {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 24px;
    padding: 24px 20px;
    width: 100%;
    background-color: $color-white;
    border-radius: 12px;
    transition: box-shadow $trans;
    position: relative;

    &:hover {
      box-shadow: $card-shadow-hover;
    }

    .MobMediaBtn {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 80px;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      border: none;
      color: $color-white;

      img {
        height: 100%;
        width: 100%;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        object-fit: cover;
        object-position: center;
        aspect-ratio: auto 3/2;
      }

      .IconConatiner {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        height: inherit;
        width: 50px;
        height: 50px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 50;
        background: rgba($color-black, 0.4);
        border-radius: 100px;
      }
    }

    .LeftSide {
      margin-top: 80px;
      .TopPart {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;
        margin-bottom: 10px;

        img {
          max-width: 48px;
          max-height: 48px;
          object-fit: cover;
          border-radius: 12px;
        }

        .MainInfo {
          :global(.mu-h4) {
            margin-bottom: 2px;
          }

          .Title {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 11px;

            .Icons {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              gap: 8px;

              > i {
                color: $color-black;
              }
            }
          }
        }

        @media (min-width: $md) {
          gap: 24px;
          margin-bottom: 24px;
        }
      }

      .MobileCenterPart {
        margin-bottom: 10px;

        @media (min-width: $md) {
          display: none;
        }
      }

      @media (min-width: $sm) {
        margin-top: 0px;
      }
    }

    .RightSide {
      .TopPart {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 18px;
        margin-bottom: 25px;

        @media (min-width: $sm) {
          gap: 20px;
        }

        @media (min-width: $md) {
          margin-bottom: 24px;
          justify-content: space-between;
        }
      }

      .BottomPart {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 12px;

        .MediaBtn {
          position: relative;
          width: 54px;
          height: 40px;
          border-radius: 12px;
          border: none;
          color: $color-white;

          > img {
            height: 100%;
            width: 100%;
            border-radius: 12px;
            object-fit: cover;
            object-position: center;
          }

          .IconConatiner {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            height: inherit;
            width: 100%;
            top: 0px;
            left: 0px;
            z-index: 50;
            border-radius: 12px;
            background-color: rgba($color-black, 0.3);
          }
        }

        .Details {
          flex: 1;

          @media (min-width: $md) {
            flex: unset;
          }
        }
      }
    }

    @media (min-width: $sm) {
      grid-template-columns: 1fr auto;
      column-gap: 24px;
    }
  }
}
