@import 'scss/variables';

body {
  .ReadBlock {
    display: flex;
    flex-direction: column;
    gap: 14px;
    background-color: $color-white;
    border-radius: 12px;
    border: 1px solid $color-black;
    box-shadow: 16px 16px 0px #f2f2f4;
    margin: 36px 16px !important;
    padding: 24px;

    .Left {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .ImagesContainer {
        display: none;
        justify-content: center;
        align-items: center;
        min-width: 96px;
        height: 96px;
        border-radius: 13px;

        .ImageArticle {
          width: 96px;
          height: 96px;
          border-radius: 13px;
        }

        @media (min-width: $sm) {
          display: flex;
        }
      }

      .ArticleContent {
        display: flex;
        gap: 8px;
        flex-direction: column;
        max-width: 387px;

        .Date {
          display: flex;
          flex-direction: row;
          gap: 10px;
          align-items: center;

          .Link {
            .Title {
              white-space: nowrap;
            }

            > p {
              color: $color-red5;
            }
          }

          .Name {
            color: $color-black4;
          }
        }

        @media (min-width: $sm) {
          gap: 0px;
        }
      }

      @media (min-width: $sm) {
        padding-left: 0px;
        padding-right: 0px;
        flex-direction: row;
      }
    }

    .Graph {
      display: flex;
      gap: 15px;
      align-items: flex-end;
      flex: 1;

      > button {
        height: 40px;
        width: 96px;
      }
    }

    @media (min-width: $sm) {
      flex-direction: row;
      gap: 40px;
      padding: 24px 20px;
      margin: 52px 32px !important;
    }
  }
}
