@import 'scss/variables';

body {
  .InfoBlock {
    height: 50px;

    .Title {
      margin-bottom: 4px;
      color: $color-black4;
    }

    .Content {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .Low {
        > i {
          color: $color-green;
          font-size: 24px;
        }
      }

      .Medium {
        > i {
          color: $color-yellow;
          font-size: 24px;
        }
      }

      .Hight {
        > i {
          color: $color-red;
          font-size: 24px;
        }
      }

      .Return {
        > i {
          color: $color-green;
          font-size: 22px;
        }
      }

      .MinInvest {
        > i {
          color: $color-green;
          font-size: 22px;
        }
      }

      .Fees {
        > i {
          color: $color-green;
          font-size: 22px;
        }
      }

      @media (min-width: $sm) {
        justify-content: flex-end;

        &.ToLeft {
          justify-content: flex-start;
        }
      }
    }
  }
}
