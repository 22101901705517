@import 'scss/variables';

body {
  .Btns {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;

    .FilterBtn {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 8px 15px;
      font-size: 15px;
      line-height: 20px;
      white-space: nowrap;
      color: $color-black2;
      background-color: $color-white;
      border: 1px solid $color-black7;
      border-radius: 18px;
      transition: border-color $trans;

      &.FilterBtnActive {
        color: $color-red5;
        border-color: $color-red5;

        .DropDown {
          color: inherit;
        }
      }

      &:hover {
        border-color: $color-black5;
      }

      .DropDown {
        margin-left: 10px;
        color: $color-black3;
      }
    }

    .ClearBtn {
      padding: 8px 16px;
      font-size: 15px;
      font-weight: 500;
      line-height: 20px;
      color: $color-red5;
      background-color: transparent;
      border: none;
    }

    @media (min-width: $md) {
      padding-bottom: 24px;
      border-bottom: 1px solid $color-black8;
    }
  }
}
