@import 'scss/variables';

@mixin p1() {
  font-family: $font-main;
  font-size: 18px;
  line-height: 32px !important;

  @media (min-width: $md) {
    font-size: 20px;
  }

  @media (min-width: $lg) {
    font-size: 24px;
  }
}

@mixin p2() {
  font-family: $font-main;
  font-size: 16px !important;
  line-height: 22px !important;

  @media (min-width: $lg) {
    font-size: 18px !important;
    line-height: 24px !important;
  }
}

@mixin h2() {
  font-family: $font-heading !important;
  font-weight: 800 !important;
  font-style: normal !important;
  font-size: 32px !important;
  line-height: 36px !important;

  @media (min-width: $md) {
    font-size: 36px !important;
    line-height: 40px !important;
  }

  @media (min-width: $lg) {
    font-size: 48px !important;
    line-height: 56px !important;
  }
}

@mixin h3() {
  font-family: $font-heading !important;
  font-weight: 800 !important;
  font-style: normal !important;
  font-size: 24px !important;
  line-height: 32px !important;

  @media (min-width: $md) {
    font-size: 28px !important;
    line-height: 36px !important;
  }

  @media (min-width: $lg) {
    font-size: 32px !important;
    line-height: 40px !important;
  }
}

@mixin h4() {
  font-family: $font-heading !important;
  font-weight: 800 !important;
  font-style: normal !important;
  font-size: 20px !important;
  line-height: 28px !important;

  @media (min-width: $lg) {
    font-size: 24px !important;
    line-height: 32px !important;
  }
}

@mixin bullet() {
  ul,
  ol {
    padding-left: 20px;

    li {
      * &::marker {
        color: $color-red5;
      }
    }
  }

  li {
    position: relative;
    margin-top: 12px;
    margin-bottom: 12px;
    padding-left: 20px;
    vertical-align: middle;
  }
}

@mixin bulletNested() {
  ul {
    padding: 0;
    list-style: none !important;

    li {
      &::before {
        content: '•';
        position: absolute;
        top: 0;
        left: 0;
        color: $color-red5;
      }
    }
  }

  ol {
    list-style: none !important;
    counter-reset: li;

    li {
      counter-increment: li;

      &::before {
        content: counter(li) '.';
        color: $color-red5;
        display: inline-block;
        width: 1em;
        margin-left: -1.5em;
        margin-right: 0.5em;
        text-align: right;
      }
    }
  }

  li {
    position: relative;
    margin-top: 12px;
    margin-bottom: 12px;
    padding-left: 30px;
    vertical-align: middle;
  }
}

// styles for RTE field
@mixin rteField() {
  h2,
  h3,
  h4 {
    margin-top: 42px;
    margin-bottom: 32px;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  h2 {
    @include h2();

    * {
      @include h2();
    }
  }

  h3 {
    @include h3();

    * {
      @include h3();
    }
  }

  h4 {
    @include h4();

    * {
      @include h4();
    }
  }

  figure {
    width: 100% !important;
    text-align: center;
    margin-bottom: 48px;

    img {
      filter: none;
      border-radius: 16px;
    }
  }

  figure[class='table'] {
    overflow-x: auto;
  }

  table {
    border-collapse: separate;
    border-spacing: 0pt;

    td,
    th {
      padding: 8px;
      border: 1px solid $color-black9;
      text-align: left;
      vertical-align: top;

      @media (min-width: $sm) {
        padding: 14px;
      }
    }

    tr:first-child {
      th:first-child,
      td:first-child {
        border-top-left-radius: 16px;
      }

      th:last-child,
      td:last-child {
        border-top-right-radius: 16px;
      }
    }

    tr:last-child {
      th:first-child,
      td:first-child {
        border-bottom-left-radius: 16px;
      }

      th:last-child,
      td:last-child {
        border-bottom-right-radius: 16px;
      }
    }
  }

  img {
    border-radius: 16px;
  }

  p {
    margin-bottom: 28px;
    word-break: break-word;

    &:last-child {
      margin-bottom: 0;
    }

    a {
      color: $color-black !important;
      text-decoration: none !important;
      border-bottom: 2px solid $color-red;
      padding-bottom: 0px;
    }
  }

  h2,
  h3,
  h4 {
    margin-top: 42px;
    margin-bottom: 32px;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  strong {
    font-weight: bold !important;
  }

  i {
    font-style: italic !important;
  }

  a {
    display: inline;
    color: $color-black !important;
    text-decoration: none !important;
    border-bottom: 2px solid $color-red;
    padding-bottom: 0px;

    * {
      color: $color-black !important;
      text-decoration: none !important;
      border-bottom: 2px solid $color-red;
      padding-bottom: 0px;
    }
  }

  @include bullet();
}

// style for custom Circle button
@mixin buttonCircleOutline() {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border: 1px solid $color-black2 !important;
  border-radius: 50%;
  transition: color 0.5s ease, border-color 0.5s ease, background-color 0.5s ease !important;

  &:hover {
    background: $color-black !important;
    border: none !important;

    i {
      color: $color-white;
    }
  }

  i {
    color: $color-black2;
    font-size: 22px;
  }
}

@mixin Lock() {
  padding-top: 60px;
  filter: blur(10px);
  height: 498px;
  background-color: $color-white;
  overflow: hidden;
  pointer-events: none;
  user-select: none;

  @media (min-width: $sm) {
    height: 284px;
  }
}
