@import 'scss/variables';

body {
  .TypeFilter {
    display: flex;
    align-items: center;
    padding: 5px 12px 7px;
    gap: 8px;
    height: 32px;
    background-color: rgba(35, 205, 195, 0.1);
    border: 1px solid rgba(35, 205, 195, 0.1);
    border-radius: 20px;
    cursor: pointer;

    &:hover {
      border: 1px solid rgba(35, 205, 195, 0.7);
    }
  }
}
